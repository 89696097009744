import { faDiscord, faGithub, faInstagram, faLinkedin, faSoundcloud, faTwitch, faYoutube, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import './main.scss';

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      },
    },
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allMdx{
      edges {
        node {
          excerpt
          fields {
            slug
          }
        }
      }
    }
    allFile {
      edges {
        node {
          extension
        }
      }
    }
    site {
      siteMetadata {
        name
        tagline
      }
    }
  }
`;

interface LinkedIconWithTextProps {
  faIcon: IconDefinition,
  iconAltText?: string,
  url?: string,
  classes?: string,
  helperText?: string,
  iconClasses?: string,
  helperTextClasses?: string
}

const LinkedIconWithText: React.FunctionComponent<LinkedIconWithTextProps> = (props: LinkedIconWithTextProps) => {
  const content = (
    <div className="row align-items-center">
      <FontAwesomeIcon className={`mobile-icon col-2 float-left ${props.iconClasses}`} title={props.iconAltText} icon={props.faIcon}></FontAwesomeIcon>
      <p className={`col ${props.helperTextClasses || ''} text-dark mb-0`}>{props.helperText}</p>
    </div>
  );

  if (props.url) {
    return <a href={props.url}>{content}</a>
  }
  else {
    return content;
  }
}
export default class IndexPage extends React.Component<IndexPageProps, {}> {

  public render() {
    const {
      name,
      tagline,
    } = this.props.data.site.siteMetadata;
    console.log(this.props.data);
    const obj = {

      site: {
        siteMetadata: {
          name: name,
          tagline: tagline
        }
      }

    }

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Joey Albano - Software Engineer</title>
          <link rel="canonical" href="https://joeyalbano.com" />
          <meta name="description" content="Joey Albano's personal website. Contains contact information and career+educational history for Joey Albano, a software
          engineer based in the Bay Area." />
        </Helmet>
        <div className="container mw-600">
          <div className="row justify-content-center"><img src="/assets/self-square.jpg" className="circ"></img></div>
          <h1>Hello!</h1>
          <p>I'm Joey, a software engineer based out of the Bay Area.</p>
          <p>Current occupation: Software Development Engineer at Amazon.</p>
          <p>Other joys: music, picking up metal things + putting them down, gaming.</p>

          <p>You can learn more about me by visiting the <a href="/about">about me page</a>.  </p>

          <div>Reach out to me on any of the platforms below! Ordered by frequency of use (descending) 🙂</div>

          <div className="container p-0 pt-3">
            <LinkedIconWithText url="mailto:joeycalbano@gmail.com" iconClasses="fa-google-plus" helperTextClasses="" faIcon={faEnvelope} iconAltText="gmail" helperText="joeycalbano@gmail.com"></LinkedIconWithText>
            <LinkedIconWithText url="https://instagram.com/joeycalbano" faIcon={faInstagram} iconAltText="instagram" helperText="joeycalbano"></LinkedIconWithText>
            <LinkedIconWithText faIcon={faDiscord} iconAltText="discord" helperText="Jdude#0726"></LinkedIconWithText>
            <LinkedIconWithText url="https://linkedin.com/in/jalbatross" faIcon={faLinkedin} iconAltText=" linkedin" helperText="jalbatross"></LinkedIconWithText>
            <LinkedIconWithText url="https://github.com/jalbatross" faIcon={faGithub} iconAltText="github" helperText="jalbatross"></LinkedIconWithText>
            <LinkedIconWithText url="https://soundcloud.com/joeyalbatross" faIcon={faSoundcloud} iconAltText="soundcloud" helperText="joeyalbatross"></LinkedIconWithText>
            <LinkedIconWithText url="https://www.youtube.com/channel/UCicfzr03sMfe-4SXewEJfkA/featured" faIcon={faYoutube} iconAltText="youtube" helperText="Joey Albano"></LinkedIconWithText>
            <LinkedIconWithText url="https://twitch.tv/jalbyy" faIcon={faTwitch} iconAltText="twitch" helperText="jalbyy"></LinkedIconWithText>
          </div>
        </div>
      </Layout >
    );
  }
}
